/* website: 3702-southshorechev
 * created at 2022-03-25 17:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/gmc-preferred.scss";
@import "../utils/icons.scss";

.widget-sr {
    &.dealer__southshorechevroletbuickgmc-885 {
        .dropdown{
            margin-top: 0;
        }
        .LoginMenu .dropdown-trigger{
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
  }


@include breakpoint(mobile){
    .widget-sr {
      &.dealer__southshorechevroletbuickgmc-885 {
        .LoginBanner{
          padding: 0px!important;
        }
        .LoginBanner .LoginBannerWrapper {
          height: 35px;
          max-height: 35px;
          .login-content {
            display: flex;
            padding-right: 0;
            #button-login,
            #button-register {
              font-size: 12px !important;
              .label{
                font-size: 12px !important;
              }
            }
  
            #button-login.btn-login {
              margin-left: 10px;
            }
  
            #button-register.btn-register {
              margin-left: 0;
              min-width: 110px !important;
              padding: 5px !important;
            }
          }
        }
      }
    }
  }